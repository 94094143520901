
<template>
  <div>
    <section class="main1">
      <div>
        <b-tabs>
          <b-tab @click="callTab1()">
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("Cart") }}</strong>
            </template>
            <MaterialCarts />
          </b-tab>
          <b-tab
            @click="callTab2()"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("Ordered") }}</strong>
            </template>
            <div
              style="display: flex; flex-direction: row;"
              @click="closeSidebar"
            >
              <OrderedMaterials @changeState="changeValueOfState" />
              <MaterialOrderItems style="margin-left: 50px;" />
            </div>
          </b-tab>
          <b-tab
            @click="callTab3()"
          >
            <template #title>
              <strong style="font-weight: 500; color: black">{{ $t("PurchaseBook") }}</strong>
            </template>
            <div
              style="display: flex; flex-direction: row;"
              @click="closeSidebar"
            >
              <CartPurchaseBook />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>

  </div></template>

<script>

import { mapGetters, mapActions } from 'vuex';
import OrderedMaterials from '@/components/cart/OrderedMaterials.vue'
import MaterialCarts from '@/components/cart/MaterialCarts.vue'
import MaterialOrderItems from '@/components/cart/MaterialOrderItems.vue'
import CartPurchaseBook from '@/components/cart/CartPurchaseBook.vue'


export default {
  components: {
    MaterialCarts,
    OrderedMaterials,
    MaterialOrderItems,
    CartPurchaseBook,
  },
  data() {
    return {
      orderNumber: '',
      productMatObj: {},
      orderIdName: null,
      stateNow: null,
    }
  },
  computed: {
    ...mapGetters(['getIsLoading']),
  },
  watch: {
    // eslint-disable-next-line func-names
    inside(item) {
      this.productMatObj = item
    },
  },
  mounted() {

  },
  methods: {
    ...mapActions(['loadOrderedMaterials', 'loadCartItems']),
    changeValueOfState(value) {
      this.stateNow = value
      console.log('valuestate', value)
    },
    callTab1() {
      this.loadCartItems({ state: 'Pending' })
    },
    closeSidebar() {
      this.$root.$emit('bv::hide::modal', 'sidebar-right');
    },
    callTab2() {
      if (this.stateNow == 'Ordered' || this.stateNow == null) {
        this.loadOrderedMaterials({
          orderCartType: 'Cart',
          state: 'Ordered',
          year: new Date().getFullYear().toString(),
          pageNumber: 1,
          pageSize: 15,
        })
      } else {
        this.loadOrderedMaterials({
          orderCartType: 'Cart',
          state: this.stateNow,
          year: new Date().getFullYear().toString(),
          pageNumber: 1,
          pageSize: 15,
        })
      }
    },
    inside(item) {
      this.productMatObj = item
    },
    getON(id) {
      this.orderNumber = id
    },
    orderID(value) {
      this.orderIdName = value
    },
  },
}
</script>

<style scoped lang="scss">
.main1{
  width:100%;
  padding: 85px 20px 15px 110px;
}

@media screen and (max-width: 1500px){
  .main1{
    padding: 85px 20px 15px 100px;
  }
}

@media screen and (max-width: 1340px){
  .main1{
    padding: 75px 15px 15px 90px;
  }
}
 @media screen and (max-width: 1190px) {
  .main1 {
    padding: 80px 10px 15px 10px;
    justify-content: center;
  }
 }
 @media screen and (max-width: 780px) {
  .main1 {
    .flex {
      flex-direction: column;
      .color-listing__wrapper {
        margin-left: 0;
      }
    }
  }
 }
</style>
